import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const initialState = {
  managerUserMapping: [],
  reportingUsers: [],
  loading: false,
  infoLading: false,
  infoSuccess:false
}

export const submitManagerToEmployee = createAsyncThunk(
  'submitManagerToEmployee/fetch',
  async (info) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/MappingManagerToUserData`,
        info,
      )
      if (response.data) {
        return response.data.manager_to_user
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchManagerToUserMapping = createAsyncThunk(
  'managerUser/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getManagerToUserMappingData`,
      )
      if (response.data) {
        return response.data.manager_to_user
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchReportingUser = createAsyncThunk(
  'fetchReportingUser/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getMappingUserData`,
      )
      if (response.data) {
        return response.data.user_list
      }
    } catch (err) {
      return err
    }
  },
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchManagerToUserMapping.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchManagerToUserMapping.fulfilled, (state, action) => {
        state.loading = false
        state.managerUserMapping = action.payload
      })
      .addCase(fetchManagerToUserMapping.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(fetchReportingUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchReportingUser.fulfilled, (state, action) => {
        state.loading = false
        state.reportingUsers = action.payload
      })
      .addCase(fetchReportingUser.rejected, (state, action) => {
        state.loading = false
      })
      /// submit to manager data
      .addCase(submitManagerToEmployee.pending, (state, action) => {
        state.loading = true
        state.infoLading = true
        state.infoSuccess= false
      })
      .addCase(submitManagerToEmployee.fulfilled, (state, action) => {
        state.loading = false
        state.infoLading = false
        state.infoSuccess=true
      })
      .addCase(submitManagerToEmployee.rejected, (state, action) => {
        state.loading = false
        state.infoLading = false
        state.infoSuccess=false
      })
  },
})

export default adminSlice.reducer
