import React, { Children } from 'react'
import classNames from 'classnames'


export default function Select({ data,handleSelect,label,className,selectedValue}) {
    console.log('===selectedValue====',selectedValue)
    const selectClass = classNames(
		className
,
		'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-2',
	)
  return (
    <>
    <label className='text-green-100 font-semibold'>{label}</label>
       <select
          id="countries"
          onChange={handleSelect}
          value={selectedValue}
          className={selectClass}
        >
          {data.map((ele) => {
            return <option value={ele.id}>{ele.name}</option>
          })}
        </select>
    </>
  )
}
