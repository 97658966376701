import { configureStore } from '@reduxjs/toolkit';
import attendanceReducer from 'components/rawReport/rawSlice';
import citizenReport from 'components/citizenReport/citizenReportSlice';
import fuelUsage from 'components/fuelUsage/fuelUsageSlice';
import potHole from 'components/pothole/potholeSlice';
import dashBoardReducer from 'components/dashboard/dashBoardSlice';
import csBinReducer from 'components/rawReport/CsBinCleared/csBinSlice';
import adminReducer from 'components/admin/adminSlice';
import trackSupervisorReducer from 'components/rawReport/TrackSuperVisor/trackSupervisorSlice';
import commonReducer from './base/commonSlice'
import  sessionReducer from './auth/sessionSlice';
import  userReducer from './auth/userSlice';
import  themeReducer from './theme/themeSlice';

export const store = configureStore({
  reducer: {
    attendance:attendanceReducer,
    csbin:csBinReducer,
    attendance:attendanceReducer,
    common:commonReducer,
      session:sessionReducer,
    user:userReducer,
    dashboard:dashBoardReducer,
    theme:themeReducer,
    trackSuperVisor:trackSupervisorReducer,
    citizenReport:citizenReport,
    fuelUsage:fuelUsage,
    potHole:potHole,
    admin:adminReducer
  }
});
