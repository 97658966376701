import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const initialState = {
  vehicleData: [],
  staffInfo: {},
  vehicleRoutes: {},
  truckData: [],
  citizenComplaints: {
    data: [],
    loading: false,
  },
  loading: false,
}

export const fetchStaffData = createAsyncThunk(
  'staffInfo/fetch',
  async (user_id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getStaffSummary`,
        {
          user_id: '509',
        },
      )
      return response.data
    } catch (err) {
      return err
    }
  },
)

export const fetchVehicleData = createAsyncThunk(
  'vehicle/fetch',
  async (user_id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getVehicleData`,
        {
          user_id: '509',
        },
      )
      if (response.data) {
        return response.data.vehicle_data
      }
    } catch (err) {
      return err
    }
  },
)
let axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}
export const fetchVehicleRoutes = createAsyncThunk(
  'vehicleRoutes/fetch',
  async (vehicle_id) => {
    let data = {
      vehicle_id: vehicle_id,
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getVehicleRoutes`,
        data,
        axiosConfig,
      )
      if (response.data) {
        return response.data?.vehicle_routes
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchTruckData = createAsyncThunk(
  'fetchTruckData/fetch',
  async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/truck_data`,
      )
      if (response.data) {
        return response.data.data
      }
    } catch (err) {
      return err
    }
  },
)
//   export const fetchCitizenCompalintsData = createAsyncThunk('citizenComplaints/fetch', async (selectedDate) => {
//     try {
//       const response =await axios.post('https://dashboard.teamapcc.com/TeamApcc/index.php/api/Map/getCitizenComplaintsSummary',{
//         user_id:user_id
//       })
//       return response.data
//     } catch(err) {
//       return err;
//     }

//   })

//   export const fetchCitizenCompalintsData = createAsyncThunk('citizenComplaints/fetch', async (selectedDate) => {
//     try {
//       const response =await axios.post('https://dashboard.teamapcc.com/TeamApcc/index.php/api/Map/getCitizenComplaintsSummary',{
//         user_id:user_id
//       })
//       return response.data
//     } catch(err) {
//       return err;
//     }

//   })

export const dashBoardSlice = createSlice({
  name: 'dashBoard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStaffData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchStaffData.fulfilled, (state, action) => {
        state.loading = false
        state.staffInfo = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchStaffData.rejected, (state, action) => {
        state.loading = false
      })

      ///  vEHICLE dATA
      .addCase(fetchVehicleData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchVehicleData.fulfilled, (state, action) => {
        state.loading = false
        state.vehicleData = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchVehicleData.rejected, (state, action) => {
        state.loading = false
      })

      /// VEHICLErOUTES
      .addCase(fetchVehicleRoutes.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchVehicleRoutes.fulfilled, (state, action) => {
        state.loading = false
        state.vehicleRoutes = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchVehicleRoutes.rejected, (state, action) => {
        state.loading = false
      })

      /// TruckData
      .addCase(fetchTruckData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchTruckData.fulfilled, (state, action) => {
        state.loading = false
        state.truckData = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchTruckData.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default dashBoardSlice.reducer
