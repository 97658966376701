import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const initialState = {
  loading: false,
  citizenReport: [],
}
export const fetchCitizenReport = createAsyncThunk(
  'attendance/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getcitizenSummary`,
      )
      if (response.data.citizen_report) {
        return response.data.citizen_report
      }
    } catch (err) {
      return err
    }
  },
)

export const postStatusData = createAsyncThunk(
  'postStatusData/fetch',
  async (formValues) => {
    let formData = new FormData()

    formData.append('image', formValues.image)
    formData.append('complaint_id', formValues.complaint_id)
    formData.append('status', formValues.status)
    formData.append('user_id', formValues.user_id)
    formData.append('comment', formValues.comment)
    console.log('formdata', formData)
    let data =JSON.stringify(...formData)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    try {
      const response = await axios.post(
        'https://dashboard.teamapcc.com/TeamApcc_Dashboard/index.php/api/TeamApccDashboard/updateCitizenSummary',
        {
          data,
        },
        config,
      )
      return response.data
    } catch (err) {
      return err
    }
  },
)

export const citizenReportSlice = createSlice({
  name: 'citizenReport',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCitizenReport.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchCitizenReport.fulfilled, (state, action) => {
        state.loading = false
        state.citizenReport = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchCitizenReport.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(postStatusData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(postStatusData.fulfilled, (state, action) => {
        state.loading = false
        // Add any fetched posts to the array
      })
      .addCase(postStatusData.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default citizenReportSlice.reducer
